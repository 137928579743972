import React from "react";

import fcupconnect from "../../assets/images/learn/fup-connect-trainer-img.png";

import fcupdownload from "../../assets/images/learn/fup-download-app-img.png";

import fcuppractise from "../../assets/images/learn/fup-practice-ur-own-img.png";

import { Link } from "gatsby";

export default function Nextstepssec() {
  return (
    <section className="nextsec-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12 cent-item">
            <h4 className="theme-h4 theme-color masterclass-archieve">
              {" "}
              THE NEXT STEPS TO HEARTFULNESS
            </h4>
          </div>
          <hr/>
        </div>

        <div class="py-3">
          <div class="row">
            <div class="col-md-3">
              <img className="img-fluid" src={fcupconnect} alt="Connect" />
            </div>
            <div class="col-md-9">
              <h4 class="theme-h4 my-3">
                <Link
                  to="//heartspots.heartfulness.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span class="theme-color">Connect</span>
                </Link>{" "}
                <span class="black-text">with a local trainer</span>
              </h4>
              <p class="font-light padd-b20">
                We offer over 6,000 global HeartSpots, where you can take a
                deeper dive into meditation with entirely free periodic
                assistance from a certified Heartfulness trainer.
              </p>

              <a
                className="btn btn-pink"
                href="//heartspots.heartfulness.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                LET’S BEGIN
              </a>
            </div>
          </div>
        </div>

        <div class="py-3">
          <div class="row">
            <div class="col-md-3">
              <img className="img-fluid" src={fcupdownload} alt="Heartssapp" />
            </div>
            <div class="col-md-9">
              <h4 class="theme-h4 my-3">
                <span class="theme-color">Download</span>{" "}
                <span class="black-text">HeartsApp</span>
              </h4>
              <p class="font-light padd-b20">
                Inspiration, simplified.
                <br />
                Our app lets you customize the daily discipline of meditation.
                Masterclasses and training guides are always with you.
              </p>
              <p>
                <Link
                  className="white-text waves-effect waves-light btn   btn-pink"
                  to="//itunes.apple.com/us/app/heartsapp-by-heartfulness/id1438627629?mt=8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  IOS
                </Link>
                &nbsp;&nbsp;
                <Link
                  className="white-text waves-effect waves-light btn  btn-pink"
                  to="//play.google.com/store/apps/details?id=com.hfn.unified"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ANDROID
                </Link>
              </p>
            </div>
          </div>
        </div>

        <div class="py-3">
          <div class="row">
            <div class="col-md-3">
              <img className="img-fluid" src={fcuppractise} alt="Heartssapp" />
            </div>
            <div class="col-md-9">
              <h4 class="theme-h4 my-3">
                <span class="theme-color">Learn</span>{" "}
                <span class="black-text">to practice on your own</span>
              </h4>
              <p class="theme-p-tag padd-b20">
                Imagine a PDF – 7,000 years in the making. Our study site and
                training outline will guide you from our early Raja Yoga
                practice to evolved meditation. Quietly and conveniently.
              </p>

              <Link to="/practice-on-my-own"  class="btn  btn-pink">
                      START
                      </Link>

            </div>
          </div>
        </div>

        <hr/>
        
      </div>
    </section>
  );
}
