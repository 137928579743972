import React from "react";

import hfnjanmag from "../../assets/images/learn/HFN_Mag_JAN18_EN_cover.jpg";

export default function Addtionalresoucele() {
  return (
    <section className="addtional-resouce-wrapeer pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12 cent-item">
            <hr />
            <h4 className="theme-h4 theme-color masterclass-archieve"> ADDITIONAL RESOURCES </h4>
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4 offset-sm-4">
            <img
              className="img-fluid align-center-600-img"
              src={hfnjanmag}
              alt="Magazine subscribe"
            />
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="mag-subs text-center">
                <a className="white-text waves-effect waves-light btn btn-pink"
                href="http://www.heartfulnessmagazine.com/subscribe/" target="_blank" rel="noopener noreferrer">SUBSCRIBE</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
