import React from "react";

// import { frequentlyList } from "../../assets/data/frequntly-question";

import { Accordion } from "react-bootstrap";

import Table from "react-bootstrap/Table";
import { Link } from "gatsby";

export default function Frequentlyquestionsec() {
  return (
    <section className="frequentquestion-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12 cent-item">
            <h4 className="theme-h4 theme-color masterclass-archieve">
              {" "}
              Frequently asked questions
            </h4>
            <p className="text-center">
              <Link className="pink-link" to="//cdn-prod.heartfulness.org/hfn/files/FAQ_09-02-17.pdf">
                {" "}
                Download FAQ
              </Link>
            </p>
          </div>
        </div>

        <div className="row pad20">
          <div className="col-md-12">
            <Accordion className="learn-faq-accordion-wrapper">
              <Accordion.Item eventKey="0">
                <Accordion.Header >
                  {" "}
                  What are these masterclasses about?{" "}
                </Accordion.Header>
                <Accordion.Body>
                  Each masterclass takes you practically through the
                  Heartfulness practices, enabling you to relax, meditate and
                  expand your consciousness, purify and rejuvenate your mind at
                  the end of a day, and establish a connection with your inner
                  Self.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  {" "}
                  How long are the masterclasses?{" "}
                </Accordion.Header>
                <Accordion.Body>
                  Each class is 30 to 40 minutes and available in multiple
                  languages. You may choose as per your preference.You may view
                  each class as many times as you wish. Try to view one class
                  per day. Having done this, we invite you to practice the
                  techniques on your own or work with certified Heartfulness
                  trainers to deepen your experience and realize the best
                  benefits.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  {" "}
                  Where can I find the instructions for the different practices?{" "}
                </Accordion.Header>
                <Accordion.Body>
                  They are available here on the website and also given as a
                  downloadable pdf at{" "}
                  <a
                    href="https://heartfulness.org/en/practice-heartfulness-with-a-guide/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Heartfulness Practice
                  </a>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  {" "}
                  Is there any specific time the Heartfulness techniques should
                  be practiced, and for how long?{" "}
                </Accordion.Header>
                <Accordion.Body>
                  They are available here on the website and also given as a
                  downloadable pdf at{" "}
                  <div className="table-responsive">
                    <Table bordered>
                      <thead className="faq-table-head">
                        <tr className="faq-table-row">
                          <td className="faq-table-head">
                            <strong>Heartfulness Technique</strong>
                          </td>
                          <td className="faq-table-head">
                            <strong>Recommended time to practice</strong>
                          </td>
                          <td className="faq-table-head">
                            <strong>Duration</strong>
                          </td>
                        </tr>
                      </thead>
                      <tbody className="faq-tablebody">
                        <tr className="faq-table-row">
                          <td className="faq-table-content">Relaxation</td>
                          <td className="faq-table-content">Anytime you like</td>
                          <td className="faq-table-content">
                            A few minutes as you need
                          </td>
                        </tr>
                        <tr className="faq-table-row">
                          <td className="faq-table-content">Meditation</td>
                          <td className="faq-table-content">
                            Morning, and then any other time during the day
                          </td>
                          <td className="faq-table-content">
                            30 minutes up to 1 hour. You can start with less and
                            develop the length as it grows on you. Repeat any
                            number of times during the day, with a maximum of 1
                            hour per session.
                          </td>
                        </tr>
                        <tr className="faq-table-row">
                          <td className="faq-table-content">Cleaning</td>
                          <td className="faq-table-content">
                            Evening, after you have finished your day’s work
                          </td>
                          <td className="faq-table-content">
                            30 minutes. You can start with less and extend it to
                            30 minutes.
                          </td>
                        </tr>
                        <tr className="faq-table-row">
                          <td className="faq-table-content">
                            Connect with your inner Self
                          </td>
                          <td className="faq-table-content">
                            At bedtime, just before sleeping
                          </td>
                          <td className="faq-table-content">10 to 15 minutes.</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  {" "}
                  Is there any specific posture?{" "}
                </Accordion.Header>
                <Accordion.Body>
                  Please sit in a comfortable posture, on a chair or
                  cross-legged on the ground as per your convenience, in an
                  upright, relaxed position. The body should not disturb your
                  meditation. During meditation, you can change position if you
                  need to. Do not lie flat on your back as it may put you to
                  sleep.{" "}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  {" "}
                  What is the source of light in the heart upon which we
                  meditate?{" "}
                </Accordion.Header>
                <Accordion.Body>
                  Do not try to see light, such as a flame or candle. Instead,
                  simply assume with conviction that the light is there
                  radiating from within – light without luminosity. You need not
                  go on repeating that thought. Instead, simply observe your
                  inner being very lightly and gently, without forcing the mind
                  to concentrate.{" "}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  {" "}
                  What do I do if my mind is racing with thoughts?{" "}
                </Accordion.Header>
                <Accordion.Body>
                  It is normal to have thoughts, as they are natural – it is the
                  function of the mind to think. If your mind starts engaging
                  with other thoughts, simply return your attention to the light
                  in the heart. Over time, as a result of practicing meditation,
                  the thoughts will no longer disturb you. They will pass like
                  clouds in the sky. You will learn to dive deeper into the
                  stillness of your mind. If for any reason the thoughts are too
                  distracting, open your eyes for a few seconds and then
                  continue meditation with eyes closed.{" "}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  {" "}
                  Does Heartfulness require strenuous effort, difficult
                  techniques, a special diet, etc.?{" "}
                </Accordion.Header>
                <Accordion.Body>
                  Heartfulness offers flexibility to go at your own pace, as per
                  your own convenience. You can practice in the comfort of your
                  home or even while in flight or in a bus. As you feel the
                  benefit and as your interest grows, a normalization of effort
                  and habits will set in naturally. As your mind is refined, so
                  also will your thoughts and actions.{" "}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  {" "}
                  Are there any fees or charges for practicing Heartfulness?{" "}
                </Accordion.Header>
                <Accordion.Body>
                  Heartfulness practice, including sessions with trainers, is
                  completely free of any cost or fees. All that is required is
                  joyful willingness for self-development.{" "}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  {" "}
                  What if I do other practices?{" "}
                </Accordion.Header>
                <Accordion.Body>
                  There is no harm in doing Heartfulness in addition to your
                  other practices or worships. You will be guided naturally to
                  take the best of it as you proceed.{" "}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10">
                <Accordion.Header>
                  {" "}
                  Is Heartfulness affiliated with any religion or political
                  movement?{" "}
                </Accordion.Header>
                <Accordion.Body>
                  No. Heartfulness is open to people of any religion, political
                  leaning, race, gender and other persuasion. The only
                  requirement is a willingness to practice.{" "}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="11">
                <Accordion.Header>
                  {" "}
                  Is there any age limit to practice Heartfulness?{" "}
                </Accordion.Header>
                <Accordion.Body>
                  Anyone who is 15 years old and above may start this practice.
                  Parental permission may be taken as applicable. There is no
                  upper age limit. It is best to have sound mind to start and
                  continue this practice.{" "}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="12">
                <Accordion.Header>
                  {" "}
                  Is there anything for children less than 15 years of age?{" "}
                </Accordion.Header>
                <Accordion.Body>
                  We have a very effective relaxation technique for children.
                  Children respond very well to this and afterwards and often
                  then teach the adults how to relax!{" "}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="13">
                <Accordion.Header>
                  {" "}
                  Is there any literature available?{" "}
                </Accordion.Header>
                <Accordion.Body>
                  A lot of literature is available at our Heartfulness centres,
                  known as HeartSpots. Any trainer will be able to guide with
                  you appropriate books based on your interest. Also, our
                  Heartfulness Magazine can be downloaded as an e-book from{" "}
                  <a
                    href="http://www.heartfulnessmagazine.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.heartfulnessmagazine.com
                  </a>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
}
