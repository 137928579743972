import React from "react";

import braincup from "../../assets/images/learn/fup-brain-img-new.png";

export default function Wisdomsect() {
  return (
    <section className="wisdom-sec-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-4">
            <img className="img-fluid" src={braincup} alt="Brain" />
          </div>
          <div className="col-md-8 col-sm-8">
            <h1 class="font-light pt-4 mt-3 text-left">
              <span class="guide-font16 text-black">The next level in your meditation?</span>
              <br />
              <span class="theme-color gotham-medium top-mini13">Wisdom</span>
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
}
