import React from "react";

import { Link } from "gatsby";

export default function Furtherinformation() {
  return (
    <section className="further-information-wrapper fup-contact pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12 cent-item">
            <h4 className="theme-h4 theme-color fn-20"> For further information</h4>
            <p className="theme-p-tag text-center">
            Please contact one of our trainers at :{" "}<br/>
              <Link
              className=""
                to="//heartspots.heartfulness.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                heartspots.heartfulness.org
              </Link>
            </p>
            <p className="theme-p-tag text-center">
            Or email us at :{" "}<br/>
            <a className="" href="mailto:devanr@volunteer.heartfulness.org ">devanr@volunteer.heartfulness.org</a> <br/> 
              <a className="" href="mailto: rsa.secretary@heartfulness.org "> rsa.secretary@heartfulness.org </a> <br/> 
              <a className="" href="mailto: za.secretary@srcm.org ">   za.secretary@srcm.org</a>
            </p>
          
          </div>
        </div>
      </div>
    </section>
  );
}
