import React from "react";

export default function Masterarchive() {
  return (
    <section className="master-archieve-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12 cent-item">
            <h4 className="theme-h4 theme-color masterclass-archieve"> THE MASTERCLASS ARCHIVE</h4>
            <hr />
            <h1 className="fup-gift-heading">
              {" "}
              Our global guide is <br /> now your personal <br /> guide
            </h1>
            <h4 className="font-light pad20">
              {" "}
              All of Daaji’s meditation masterclasses are available to you.
              Free. Forever.
            </h4>
          </div>

          <div class="row">
            <div class="col-md-6 pb-4">
            <iframe 
            width="540" height="315" 
            src="https://www.youtube.com/embed/T1YQ1xZlXPw" 
            title="Your Heart is Your Laboratory | Raja Yoga Master Daaji Explains Meditation & Human Evolution" 
            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen></iframe>
            
            </div>
            <div class="col-md-6 pb-4">
              <iframe
                loading="lazy"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/-bGeWAKKckc?enablejsapi=1&amp;origin=https%3A%2F%2Fheartfulness.org"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
                data-gtm-yt-inspected-12272010_7="true"
                id="290388110"
                title="masterbeing1"
              ></iframe>
            </div>
            <div class="col-md-6 pb-4">
              <iframe
                loading="lazy"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/XrBojZA8YbA?enablejsapi=1&amp;origin=https%3A%2F%2Fheartfulness.org"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
                data-gtm-yt-inspected-12272010_7="true"
                id="109549562"
                title="masterbeing2"
              ></iframe>
            </div>
            <div class="col-md-6 pb-4">
              <iframe
                loading="lazy"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/LE_AI1Vb4C0?enablejsapi=1&amp;origin=https%3A%2F%2Fheartfulness.org"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
                data-gtm-yt-inspected-12272010_7="true"
                id="930225101"
                title="masterbeing3"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
