import React from "react";

export default function Participatinsec() {
  return (
    <section className="paticipants-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12 cent-item">
            <p class="theme-p-tag font-light">
              <span class="theme-color">Thank you</span> for participating in
              Daaji’s{" "}
              <a class="theme-link pink-link" href="/masterclass/">
                masterclasses
              </a>
              . As a next step, we offer the tools with which you can deepen
              your meditative experience – personally, peacefully, profoundly.{" "}
              <span class="theme-color">As always, Heartfulness is free.</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
