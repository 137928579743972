import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Followbreadcreumb from "../components/learn/followup-breadcumbs";

import Wisdomsect from "../components/learn/wisdom-sec";

import Participatinsec from "../components/learn/transition";

import Nextstepssec from "../components/learn/next-steps";

import Givegift from "../components/learn/give-gift";

import Masterarchive from "../components/learn/master-classarchieve";

import Addtionalresoucele from "../components/learn/addtional-resource";

import Frequentlyquestionsec from "../components/learn/frquently-question";

import Furtherinformation from "../components/learn/further-information";

// markup
const FollowPage = () => {
  return (
    <div>
      <Seo title="Followup" />

      <Layout isSticky>
        <section>
          <Followbreadcreumb />
        </section>
        <section>
          <Wisdomsect />
        </section>
        <section>
          <Participatinsec />
        </section>

        <section>
          <Nextstepssec />
        </section>

        <section>
          <Givegift />
        </section>

        <section>
          <Masterarchive />
        </section>

        <section>
          <Addtionalresoucele />
        </section>

        <section>
          <Frequentlyquestionsec />
        </section>
        <section>
          <Furtherinformation />
        </section>
      </Layout>
    </div>
  );
};

export default FollowPage;
