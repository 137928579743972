import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";

export default function Followbreadcreumb() {
  return (
    <div className="breadcumb-sub-page-top white-banner subpagepadding-left">
    <div className="container">
    <div className="breadcrumb-start px-3" >
      <div className="row">
     
        <div className="col-md-12 pad40-0">
          <div classname="cent-item">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active className="breadcrumb-line" >  <span className="left-bredcrumb">follow-up</span></Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  );
}
