import React from "react";

import { Link } from "gatsby";

export default function Givegift() {
  return (
    <section className="give-gift-wrapper fup-gift pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12 cent-item">
            <h1 className="fup-gift-heading">
              {" "}
              Give the gift <br /> of Heartfulness
            </h1>
            <h4 className="pad20 font-light"> Share your joy with family, friends and co-workers. </h4>

            <Link to="/followup" className="btn  btn-pink">
              GIVE HEARTFULNESS
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
